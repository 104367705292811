/* General Styles */

.video-tag {
    color: white;
    font-size: 300%;
    text-align: center;
}

.block {
    position: relative;
    margin: 0% auto;
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, rgb(126, 107, 107), rgb(14, 13, 13));
}

.block.glow {
    max-width: 60%;
    max-height: 30%;
}

.video-wrapper {
    position: relative;
    padding-bottom: 52.5%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glow::before,
.glow::after {
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    background: linear-gradient(45deg, yellow, orange, rgb(104, 112, 104), skyblue, pink, black, violet, red, blue);
    background-size: 400%;
    width: calc(100% + 5px);
    height: calc(100% + 5px);
    z-index: -1;
    animation: animate 20s linear infinite;
}

@keyframes animate {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.glow::after {
    filter: blur(40px);
}

label {
    display: block;
    margin-bottom: 10px;
}

/* Media Queries */

/* Large screens (laptops and PCs) */
@media (min-width: 1025px) {
    .block {
        height: 600px;
    }

    .video-tag {
        font-size: 300%;
    }
}

/* Medium screens (tablets and small laptops) */
@media (min-width: 768px) and (max-width: 1024px) {
    .block {
        height: 500px;
    }

    .video-tag {
        font-size: 250%;
    }

    .block.glow {
        max-width: 80%;
        max-height: 60%;
    }
}

/* Small screens (mobile phones) */
@media (max-width: 767px) {
    .block {
        height: auto;
    }

    .video-tag {
        font-size: 200%;
    }

    .block.glow {
        max-width: 100%;
        max-height: none;
    }

    .video-wrapper {
        padding-bottom: 56.25%; /* Maintain aspect ratio */
    }
}
