/* Navbar.css */

.navbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position:absolute;
    top: 0%; /* Adjust this value to move the navbar down */
    right: 0;
    width: 100%; /* Adjust this value to fit the navbar from the middle to the right side */
    background-color: #000000; /* Optional: add a background color if needed */
    padding: 10px; /* Optional: add padding for better spacing */
}

.navbar-logo {
    position: absolute;
    left: 1%; /* Adjust this value for logo positioning */
    top: 10px; 
    width: 10%;/* Adjust this value to align with the navbar */
     /* Adjust the height of the logo */
}

.navbar-list {
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
}

.navbar-item {
    margin-left: 20px; /* Adjust this value for spacing between navbar items */
}

.navbar-item button {
    background-color: #000000; /* Button background color */
    color: #bbbbbb; /* Button text color */
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    text-decoration: none;
    transition: background-color 0.3s;
}

.navbar-item button:hover {
    background-color: #0056b3; /* Button background color on hover */
}
