.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: -15%;
    margin-left: -10%; /* Adjust the left margin as needed */
  }
  
  .icon {
    width: 5%; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 0 10px;
    cursor: pointer;
  }
  
  .icon-2 {
    width: 10%; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
    margin: 0 10px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .container {
      justify-content: space-around; /* Ensure images stay side by side */
      margin-left: 0; /* Reset margin for smaller screens */
    }
    
    .icon, .icon-2 {
      width: 20%; /* Adjust sizes for smaller screens */
      margin: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      justify-content: space-around; /* Ensure images stay side by side */
      margin-left: 0; /* Reset margin for smaller screens */
    }
    
    .icon, .icon-2 {
      width: 20%; /* Adjust sizes for smaller screens */
    }
  }
  