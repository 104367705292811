@import url('https://fonts.cdnfonts.com/css/super-bubble');

.floating-image {
    position: absolute;
    left: -300px; /* Start off-screen to the left */
    top: 30%; /* Center vertically */
    transform: translateY(-40%);
    animation: floatImage 5s linear forwards; /* Animate for 5 seconds linearly */
  }
  
  @keyframes floatImage {
    0% {
      left: -300px; /* Start off-screen to the left */
    }
    50% {
      left: calc(50% - 150px); /* Move to the middle (assuming image width of 300px) */
    }
    100% {
      position: fixed; /* Fix the image */
      left: 50%; /* Center horizontally */
      transform: translateX(-50%) translateY(-50%);
    }
  }
  .homecolor{
    background-color: #b373fe;
  }

            
