/* Base Styles */
.faq-answer {
    display: none;
    transition: display 0.3s ease; /* Optional: Add transition effect */
}

.faq-answer.open {
    display: block;
}

.toggle-arrow {
    transition: transform 0.3s ease; /* Optional: Add transition effect */
    width: 15%;
}

.toggle-arrow.open {
    transform: rotate(180deg);
}

.faq-containers {
    border-radius: 15px;
    padding: 30px;
    margin: 0 auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 90%;
    max-width: 1100px;
    height: 10%;
}

/* Adjust FAQ item and answer positioning */
.faq-item {
    width: 80%;
    margin: 25px 5%;
    background-color: #e2cd09;
    border-radius: 10px;
    padding: 5px;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.faq-item:hover {
    background-color: #8eaf18;
}

.faq-answer {
    display: none;
    align-content: center;
    margin-top: 20px;
    transition: max-height 0.2s ease-out;
    overflow: hidden;
    width: 100%;
    font-size: 120%;
    padding-left: 0;
}

.faq-question {
    margin-top: 15px;
    display: flex;
    justify-content:center;
    width: 100%;
    cursor: pointer;
    font-size: 1.2rem;
}

.faq-question img {
    margin-top: -10px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .faq-containers {
        width: 95%;
        max-width: 1400px;
    }

    .faq-item {
        margin: 20px 2.5%;
    }
}

@media (max-width: 768px) {
    .faq-containers {
        width: 100%;
        max-width: 500px;
    }

    .faq-item {
        margin: 20px 2%;
    }
}

@media (max-width: 480px) {
    .faq-containers {
        padding: 5px;
    }

    .faq-item {
        padding: 15px;
    }

    .faq-question {
        font-size: 1rem;
    }

    .faq-answer {
        font-size: 0.9rem;
    }

    .toggle-arrow {
        width: 10%;
    }
}
