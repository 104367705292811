/* Piechart.css */

.piechart-container {
    max-width: 1300px;
    margin: 0 auto; /* Center align the container */
    padding: 20px; /* Add padding for better display */
}

.responsive-center {
    display: flex;
    justify-content: center;
}

.image-container {
    position: relative;
}

.piechart-img {
    width: 89.5%;
    height: auto;
    margin-left: 5.2%; /* Adjust left margin as needed */
}

/* Media Queries for Responsiveness */
@media only screen and (max-width: 768px) {
    .piechart-container {
        padding: 10px; /* Adjust padding for smaller screens */
    }
    .piechart-img {
        margin-left: 5%; /* Adjust left margin for smaller screens */
    }
}

@media only screen and (max-width: 480px) {
    .piechart-container {
        padding: 5px; /* Further adjust padding for smaller screens */
    }
    .piechart-img {
        margin-left: 2%; /* Adjust left margin for even smaller screens */
    }
}
