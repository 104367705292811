.roadmap{
    width: 60%;
}
@media (max-width:1424px){
    .roadmap{
        width:80%
    }
    
}
@media (max-width:768px){
    .roadmap{
        width:70%
    }
    
}
@media (max-width:480px){
    .roadmap{
        width:100%
    }
    
}
